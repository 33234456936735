body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom.css */
input {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
}

input:focus,
textarea:focus {
  outline: none;
}

.form-element {
  transition: all 0.2s ease-in-out;
}

.form-element:hover {
  background-color: #f9f9f9;
}

.drag-handle {
  padding: 0 10px;
  font-size: 18px;
  cursor: grab;
}

.form-drop-zone {
  transition: background-color 0.3s;
}

.form-drop-zone.bg-gray-100 {
  background-color: #f0f0f0;
}

/* quill-custom.css */
.ql-editor {
  font-size: 14px;
}

.modal-fullscreen {
  max-height: 100vh;
}

.modal-content-fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
}

.modal-body img {
  max-width: 100%;
  height: auto;
}

.modal-footer button {
  white-space: nowrap;
}

.ReactModal__Overlay {
  z-index: 1000;
}
